import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const WebSetupGuide = () => (
  <Layout>
    <SEO
      title="My Website Structure and Setup Guide"
      description="Here's your Website structure and setup guide. Domain name registration and Domain hosting. Setting up your website as a data scientist and programmer"
    />
    <h1>My Website Structure and Setup Guide</h1>
    <p>
      If you’ve read earlier articles or my about page then you know I got
      started in data science. Primarily using Python and a bit of R just to see
      how another language worked. I felt restricted with the options to share
      my work so I got set up with WordPress.
    </p>
    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/4ec9FNR.jpg"
        alt="Campbell Data Science website structure and setup siteground namecheap"
        description="Campbell data science website structure and set up using wordpress siteground and namecheap"
      />
    </div>
    <p>
      I wasn’t long before I started to feel restricted again so I decided to
      take some time out and do it properly. This lead me to full stack web
      development and I have never looked back.
    </p>
    <p>Here are the 4 main services/ tools I use;</p>
    <p>HTML, CSS & JavaScript for any pages I want to build.</p>
    <p>WordPress for the blog portion of my site.</p>
    <p>SiteGround for hosting. UPDATE - I now use Amazon S3</p>
    <p>Namecheap for domain name registration.</p>
    <p>
      The structure of most sites that I built include a HTML home page that is
      designed to be attractive and give users information I think they should
      know. This home page usually has links to the about and contact page if I
      haven’t included that information on the homepage. I would also have a
      link to the blog.
    </p>
    <p>
      If you don’t know already, here’s an outline of basic static page web
      development.
    </p>
    <p>
      HTML is the outline of the site, it is like the foundation and holds the
      content and basic structure of the page. CSS is what gives the page it’s
      appearance. It controls things like the font style, images size and the
      way element sit on the page. It makes things look pretty. JavaScript is
      what gives the site functionality. Anything you see that moves or
      interacts with data is probably done with JavaScript. As with any
      programming language, there are multiple ways to do the same thing. I use
      these tools but you should experiment and find what suits you.
    </p>
    <p>
      If you want a more in depth explanation on this, here’s an article called
      on{" "}
      <a
        href="https://blog.hubspot.com/marketing/web-design-html-css-javascript"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        how HTML, CSS and JavaScript work
      </a>
      .
    </p>
    <h2>A Note on WordPress</h2>
    <p>
      The blogs are usually built using WordPress, I wrote an article on why web
      developers should use WordPress if you are interested. An outline is that
      it saves time and allows me to focus on other things like marketing,
      writing content and SEO.
    </p>
    <h2>Domain Name Registration and Web Hosting</h2>
    <p>
      When you want to create a website with your own domain name, you need 2
      things. A domain name and hosting.
    </p>
    <p>
      <strong>A domain name</strong> is the address you see in the browser bar, 
      campbelldatascience.com for this site. The .com part is the domain name
      extension and you pay for each one individually on a subscription basis
      which renews yearly. I also own the .co.uk domain to prevent anyone from
      setting up a similar site and confusing my clients. There are many
      extensions to choose from and I usually go for .com and .co.uk. These are
      what people are most familiar with other than .net and .org and .gov.
    </p>
    <p>
      I register with{" "}
      <a
        href="http://www.dpbolvw.net/click-9120749-11429042"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        Namecheap
      </a>
      . I like their user interface and I have never had any problems.
    </p>
    <p>
      <strong>Web hosting</strong> is the place where your site lives. Hosting
      service providers have vast amounts of storage that they divide and sell
      to users. There are many different types of hosting plans based on your
      needs. I use a type that lets me install WordPress and host unlimited
      websites.
    </p>
    <p>
      I got the GrowBig plan from SiteGround and bought 2 years to take
      advantage of the introductory offer.
    </p>
    <h2>Making a Decision</h2>
    <p>
      Once everything is set up, it is pretty straight forward from there. The
      hard part as someone doing this for the first time is the choice of which
      service providers to go with. I know because I was in that position and
      put off making a decision for a long time. I even tried free services like
      blogger and the free tier wix for a while.
    </p>
    <p>
      Eventually I thought I need to take this seriously if I am going to get
      anywhere so I went paid.
    </p>
    <h2>Why I chose Namecheap?</h2>
    <p>
      Namecheap wasn’t actually my first choice. I went with 123-reg. The search
      for a good domain registrar wasn’t that stressful. Each had similar
      reviews and the prices didn’t vary too much. They all offer multiple
      domain name extensions and as I mentioned before, I just go with the
      .co.uk and .com for each domain name I buy.
    </p>
    <p>
      I bought my first domain name through 123-reg and when it was time to buy
      another for a different site, I decided to browse and read some more
      reviews. It was the same thing as before where there was no definitive
      answer. Everyone likes different things and there didn’t seem to be too
      much variance between them.
    </p>
    <p>
      I wanted to try something different and ended up finding Namecheap. There
      were a few things that stood out and one was that their site design is
      really good. I thought that if they put this much effort into getting the
      appearance of their site right, they might also put a lot of effort into
      their service.
    </p>
    <p>
      After giving Namecheap a chance that first time, now I buy all my domains
      through them. I’m familiar with it, I like the way the site looks, their
      service is easy to use and I’ve never had any issues at all.
    </p>
    <h2>Should I buy the domain and hosting together?</h2>
    <p>
      You might have noticed that most web hosting providers offer a free domain
      name when you sign up. I would recommend Ignoring this and not using it.
      In web development, it is good practice to remain as flexible as possible.
      Some people have no problem registering both with the same company for
      ease and convenience.
    </p>
    <p>
      I think that once you register both with one company, they have a stronger
      hold on you. When your hosting is due to expire, you should shop around
      and see what other companies offer around the web. If you have both
      registered to one company, it can be a more of a daunting task to get both
      your domain name and hosting switched.
    </p>
    <p>
      In short, aim to register your domain name with one company and keep it
      there. Aim to register your hosting with a different company that
      specialises in hosting. Be flexible and don’t be afraid to shop around for
      a different hosting provider when your plan is due to expire.
    </p>
    <h2>Which domain name extension(s) should I get?</h2>
    <p>
      I’ll give you a quick overview of the process of choosing a domain name.
      Think about what your site is about and write down a few ideas for your
      site based around this. Go on a domain name registrar like Namecheap and
      try your ideas.
    </p>
    <p>
      Check to see if the .com and .co.uk versions are available. I normally get
      both, decide on the main one for my site and set up{" "}
      <a
        href="https://www.dynadot.com/community/help/question/what-is-forwarding"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        domain forwarding
      </a>
      . This just means if someone types the .co.uk version of my site into the
      browser, they get sent to .com.
    </p>
    <p>
      Not everyone does this but is a good way of making sure users don’t get
      confused and you don’t miss out on any extra traffic.
    </p>
    <p>
      I then pick the my favourite available name out of the bunch of those
      available. The next step is to go through the checkout and purchase the
      domain names.
    </p>
    <p>
      Some people think it’s best to use a secure domain name search so the
      providers can’t use cookies to hike the price on the domain name you want.
      I haven’t done any research into this and have never had a problem not
      doing this.
    </p>
    <h2>Hard Time Choosing a Domain Name?</h2>
    <p>
      If you are really stuck for a name and really want a simple one, go for a
      different domain name extension. Sometimes people register domain names
      and don’t upload a website. Possibly to make a profit, or they just never
      got around to making the site.
    </p>
    <p>
      I suggest using a different domain name extension that suits your site but
      search the .co.uk and .com to see what is on these sites. If they are just
      holding pages good. If they are similar to what you have planned or
      something you really don’t want any chance of association with your brand
      then consider something else.
    </p>
    <h2>Domain Hosting Registration Add ons</h2>
    <p>
      You will see lots of options for buying add ons. My advice is not to buy
      any. These things change every year and as a beginner it can be hard to
      tell if you need any extras. Some of them can sound really convincing but
      just ignore them.
    </p>
    <h2>What is Whois icann - Domain privacy?</h2>
    <p>
      When you register a a domain name, you give some personal information.
      This is stored on a database. People might want to contact site owners for
      various reasons such as to purchase your domain. They can use the{" "}
      <a
        href="https://www.whois.com/whois/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        lookup service
      </a>{" "}
      to browse the database and get hold of your personal information.
    </p>
    <p>
      One of the things I really like about name cheap is that they offer
      WhoisGuard which protects your information. The best part is that it is
      free ‘for everyone forever’. Just type ‘whoisguard free’ into the name
      cheap browser to see for yourself.
    </p>
    <p>
      Domain privacy is just one of the hurdles I had to jump when I set up my
      first domains. It slowed me down as I spent days reading about whether I
      just buy it or not. You don’t have to worry about that because it is now
      just a part of their service.
    </p>
    <h2>Why I chose SiteGround?</h2>
    <p>
      The choice of which hosting provider to go with was the hardest to make.
      It is the part that requires the most financial commitment. Like I said,
      once I got past trying to do everything for free, it made the process
      easier.
    </p>
    <p>
      I did some research on hosting services and a few names came up then I
      narrowed those to the ones supported WordPress. These were Bluehost and
      SiteGround. Both seemed to offer similar services at a similar price but
      SiteGround stood out because a lot of people praised their customer
      service and scalability.
    </p>
    <h3>Introductory Offer</h3>
    <p>
      The only real downside I could find is that the price goes up after the
      special signing up offer. To help put this off, I decided I would go sign
      up for 2 years instead of 1. This was my first experience with paid
      hosting and I felt like I was taking a risk . I didn’t know what to expect
      but I can honestly say I am 100% happy with my choice. Now I host multiple
      websites using one SiteGround GrowBig plan.
    </p>
    <p>
      I also find that uploading pages I have coded myself using HTML is really
      easy. SiteGround uses a system that gives you a range of tools. You can
      view databases, add a SSL certificate (which gives you the{" "}
      <a
        href="http://www.tech-faq.com/https.html"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        https prefix
      </a>
      ), browse files, view traffic data and all sorts of other things.
    </p>
    <h3>Advanced Hosting Providers</h3>
    <p>
      As a note, I have tried{" "}
      <a
        href="https://pages.github.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        GitHub
      </a>{" "}
      and{" "}
      <a
        href="https://www.heroku.com/pricing"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Heroku
      </a>{" "}
      for tests and web applications. While these are amazing tools and very
      useful for{" "}
      <Link to="/about/" style={{ textDecoration: "none" }}>
        my work
      </Link>
      , I find they are more of an advanced so I don’t often recommend them.
    </p>
    <h2>Conclusion</h2>
    <p>
      Hopefully, you know a bit more about setting up a website with a custom
      domain as a data scientist or programmer. I’m also hoping I’ve taken away
      some of the fear and confusion around the subject. The truth is that you
      won’t know if it works for you until you try it.
    </p>
    <p>
      I want to try out some different hosting companies in the future just to
      experiment, for now though I am  very happy with what I have.
    </p>
    <p>
      If you want to know more about setting up your site after you have your
      domain name and hosting then stay tuned for future articles. If you can’t
      wait just get in touch. After all, a big part of what I do is web
      development.
    </p>

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default WebSetupGuide
